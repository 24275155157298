const euCountryCodes = [
  'AT', // Austria
  'BE', // Belguim
  'BG', // Bulgaria
  'CY', // Cyprus
  'CZ', // Czechia
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
]
const exemptableNonEuCountryCodes = [
  'AU', // Australia
  'CH', // Switzerland
  'NO', // Norway
  'NZ', // New Zealand
  'GB', // United Kingdom
]

const withDutiesNonEuCountryCodes = [
  'CH', // Switzerland
  'NO', // Norway
  'NZ', // New Zealand
]

export const exemptableCountries = [
  ...euCountryCodes,
  ...exemptableNonEuCountryCodes,
]

export const countriesWithDuty = [
  ...euCountryCodes,
  ...withDutiesNonEuCountryCodes,
]
