import { CartLineItemFragment } from '@modules/commercetools'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { DesignSalabilitiesLineItem } from 'pages/api/spoonflower/design-salabilities'
import { ProductKey } from 'pages/api/spoonflower/helpers/design.service'

interface DesignSalabilitiesResponse {
  designSalabilities: Record<string, boolean>
}

function checkIfAllDesignsAreSalable(
  lineItemIDs: Array<string>,
  designSalabilities: Record<string, boolean>
) {
  for (const id of lineItemIDs) {
    // Some line items may not contain designs, and for those we assume they are salable.
    if (designSalabilities[id] === false) {
      return false
    }
  }

  return true
}

function getGraphQLQueryVariables(
  lineItems: Array<CartLineItemFragment>
): Array<DesignSalabilitiesLineItem> {
  return (
    lineItems
      /**
       * We're filtering out line items that do not have a design associated with them
       * (for example, Sample Packs). If a product does not have a design,
       * it will automatically be considered salable, for design salability purposes.
       */
      .filter(({ custom }) =>
        Boolean(
          custom?.customFieldsRaw?.find(({ name }) => name === 'designID')
            ?.value
        )
      )
      .map(({ custom, id, productKey }) => ({
        id,
        designID: Number(
          custom?.customFieldsRaw?.find(({ name }) => name === 'designID')
            ?.value
        ),
        productKey: productKey as ProductKey,
      }))
  )
}

function useDesignSalabilities(
  lineItems: Array<CartLineItemFragment> = [],
  cartID: string = ''
) {
  const designSalabilitiesKey = ['designSalabilities', cartID]
  const variables = getGraphQLQueryVariables(lineItems)
  const query = useQuery(
    designSalabilitiesKey,
    async (context) => {
      /**
       * Line items without an associated design (for example, Sample Packs)
       * are filtered out, because we don't need to check their salability.
       * If we don't have any line items to check against, we can skip the API call.
       */
      if (variables.length === 0) {
        return {
          areAllDesignsSalable: true,
          designSalabilities: {},
        }
      }

      const designSalabilities = await axios
        .post<DesignSalabilitiesResponse>(
          '/api/spoonflower/design-salabilities',
          { lineItems: variables },
          {
            withCredentials: true,
          }
        )
        .then((response) => response.data.designSalabilities)

      /**
       * This first "areAllDesignsSalable" is needed on "refetch()",
       * so that we may determine if all designs are salable without a re-render.
       */
      const areAllDesignsSalable = checkIfAllDesignsAreSalable(
        variables.map(({ id }) => id),
        designSalabilities
      )

      return {
        areAllDesignsSalable,
        designSalabilities,
      }
    },
    {
      enabled: false,
      initialData: {
        areAllDesignsSalable: true,
        designSalabilities: {} as Record<string, boolean>,
      },
      retry: false,
    }
  )
  const {
    data: { designSalabilities },
  } = query
  /**
   * This second "areAllDesignsSalable" is needed on re-render,
   * so we know if the user has removed non-salable designs from their cart.
   */
  const areAllDesignsSalable = checkIfAllDesignsAreSalable(
    variables.map(({ id }) => id),
    designSalabilities
  )

  return {
    ...query,
    data: {
      areAllDesignsSalable,
      designSalabilities,
    },
  }
}

export { useDesignSalabilities as default }
