import { Icon, IconProps } from '@chakra-ui/react'

export const Lock: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    height="18px"
    viewBox="0 0 19 18"
    width="19px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.625 16.5C4.3125 16.5 4.04688 16.3906 3.82812 16.1719C3.60938 15.9531 3.5 15.6875 3.5 15.375V7.2375C3.5 6.925 3.60938 6.65937 3.82812 6.44062C4.04688 6.22187 4.3125 6.1125 4.625 6.1125H5.9375V4.3125C5.9375 3.325 6.28437 2.48438 6.97812 1.79063C7.67188 1.09688 8.5125 0.75 9.5 0.75C10.4875 0.75 11.3281 1.09688 12.0219 1.79063C12.7156 2.48438 13.0625 3.325 13.0625 4.3125V6.1125H14.375C14.6875 6.1125 14.9531 6.22187 15.1719 6.44062C15.3906 6.65937 15.5 6.925 15.5 7.2375V15.375C15.5 15.6875 15.3906 15.9531 15.1719 16.1719C14.9531 16.3906 14.6875 16.5 14.375 16.5H4.625ZM9.5 12.75C9.9 12.75 10.2406 12.6125 10.5219 12.3375C10.8031 12.0625 10.9438 11.7312 10.9438 11.3438C10.9438 10.9688 10.8031 10.6281 10.5219 10.3219C10.2406 10.0156 9.9 9.8625 9.5 9.8625C9.1 9.8625 8.75937 10.0156 8.47812 10.3219C8.19687 10.6281 8.05625 10.9688 8.05625 11.3438C8.05625 11.7312 8.19687 12.0625 8.47812 12.3375C8.75937 12.6125 9.1 12.75 9.5 12.75ZM7.0625 6.1125H11.9375V4.3125C11.9375 3.6375 11.7 3.0625 11.225 2.5875C10.75 2.1125 10.175 1.875 9.5 1.875C8.825 1.875 8.25 2.1125 7.775 2.5875C7.3 3.0625 7.0625 3.6375 7.0625 4.3125V6.1125Z"
      fill="currentColor"
    />
  </Icon>
)
