import { CartLineItemFragment } from '@modules/commercetools'
import { ProductKey } from '@modules/spoonflower/models/productKey'
import {
  CustomFieldDictionary,
  VariantAttributeDictionary,
} from '../hooks/useLineItemAttributes'

const getItemName = (
  lineItem: CartLineItemFragment,
  variantAttributes: VariantAttributeDictionary,
  customFields: CustomFieldDictionary
) => {
  const { designName } = customFields
  const { name, productKey } = lineItem

  switch (productKey) {
    case ProductKey.Subscriptions:
      return variantAttributes['subscription-name']?.value ?? ''
    default:
      return `${name} ${designName?.value ?? ''}`.trim()
  }
}

export default getItemName
