import { Wrap, WrapItem } from '@chakra-ui/react'
import {
  Afterpay,
  AmericanExpress,
  Discover,
  MasterCard,
  PayPal,
  Visa,
} from '@modules/ui/icons'

interface PaymentMethodsProps {
  showAmericanExpress?: boolean
  showAfterpay?: boolean
  showDiscover?: boolean
  showMasterCard?: boolean
  showPaypal?: boolean
  showVisa?: boolean
}

const PaymentMethods: React.FC<PaymentMethodsProps> = (props) => {
  const {
    showAmericanExpress = true,
    showAfterpay = true,
    showDiscover = true,
    showMasterCard = true,
    showPaypal = true,
    showVisa = true,
  } = props

  return (
    <Wrap justify="center" spacing="2">
      {showAmericanExpress && (
        <WrapItem>
          <AmericanExpress />
        </WrapItem>
      )}

      {showVisa && (
        <WrapItem>
          <Visa height="16px" width="auto" />
        </WrapItem>
      )}

      {showDiscover && (
        <WrapItem>
          <Discover height="16px" width="auto" />
        </WrapItem>
      )}

      {showMasterCard && (
        <WrapItem>
          <MasterCard height="16px" width="auto" />
        </WrapItem>
      )}

      {showPaypal && (
        <WrapItem>
          <PayPal height="12px" width="auto" />
        </WrapItem>
      )}

      {showAfterpay && (
        <WrapItem>
          <Afterpay height="12px" width="auto" />
        </WrapItem>
      )}
    </Wrap>
  )
}

export default PaymentMethods
