import React from 'react'
import { List, Stack } from '@chakra-ui/react'
import { getError, useCart } from '@modules/commercetools'
import { useToast } from '@modules/ui'

import CartItem from './components/CartItem'

export type TCartItemListProps = {
  shouldLinkToPDP?: boolean
  isEditable?: boolean
}

export const CartItemList: React.FC<TCartItemListProps> = (props) => {
  const { shouldLinkToPDP = true, isEditable = true } = props
  const toast = useToast()
  const { cart } = useCart({
    onCartItemUpdateError: (e) => toast({ status: 'error', ...getError(e) }),
    onCartItemDeleteError: (e) => toast({ status: 'error', ...getError(e) }),
  })

  return (
    <List width="full">
      <Stack
        width={'full'}
        maxW={'full'}
        spacing={{ base: '2', md: '8', xl: '16' }}
      >
        {cart?.lineItems?.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <CartItem
                index={index}
                item={item}
                shouldLinkToPDP={shouldLinkToPDP}
                isEditable={isEditable}
              />
            </React.Fragment>
          )
        })}
      </Stack>
    </List>
  )
}
