import type { FormatNumberOptions, IntlShape } from 'react-intl'

type Tax = number | 0 | null | string | undefined

export function handleCalculateTaxValue(
  tax: Tax,
  formatNumber: IntlShape['formatNumber'],
  formatMessage: IntlShape['formatMessage'],
  formatNumberOptions: FormatNumberOptions
) {
  let taxValue

  if (typeof tax === 'number' && tax !== 0) {
    taxValue = formatNumber(tax / 100, formatNumberOptions)
  } else if (tax === 0) {
    taxValue = formatNumber(0, formatNumberOptions)
  } else {
    taxValue = formatMessage({
      id: 'cart.summary.tax.calculatedAtNextStep',
    })
  }

  return taxValue
}

export function defineTaxIdentifier(country: string) {
  let taxIdentifierName

  switch (country) {
    case 'AU':
      taxIdentifierName = 'cart.summary.gst'
      break
    case 'GB':
      taxIdentifierName = 'cart.summary.vat'
      break
    case 'dutyCountry':
      taxIdentifierName = 'cart.summary.duty'
      break
    default:
      taxIdentifierName = 'cart.summary.tax'
      break
  }

  return taxIdentifierName
}
