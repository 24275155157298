import { Icon, IconProps } from '@chakra-ui/react'

export const Afterpay: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    height="31px"
    viewBox="0 0 166 31"
    width="166px"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="afterpay-logo"
    {...props}
  >
    <title id="afterpay-logo">Afterpay logo</title>
    <path
      d="M13.6622 15.1698C13.6622 12.3836 11.5666 10.4254 8.99245 10.4254C6.41829 10.4254 4.32268 12.4166 4.32268 15.1698C4.32268 17.89 6.41829 19.9143 8.99245 19.9143C11.5692 19.9117 13.6622 17.956 13.6622 15.1698ZM13.699 23.4625V21.3061C12.429 22.7996 10.5385 23.7266 8.27463 23.7266C3.57068 23.7266 0 20.0768 0 15.1698C0 10.2933 3.70741 6.58002 8.3798 6.58002C10.578 6.58002 12.4317 7.50961 13.7016 8.96749V6.87718H17.9244V23.4625H13.699Z"
      fill="black"
    />

    <path
      d="M38.4361 19.7797C36.9584 19.7797 36.5482 19.2488 36.5482 17.857V10.4939H39.2617V6.87718H36.5482V2.83119H32.2203V6.87718H26.646V5.86886C26.646 4.47448 27.1956 3.94364 28.7075 3.94364H29.6567V0.725644H27.5742C24.0035 0.725644 22.3207 1.85334 22.3207 5.30247V6.87464H19.9175V10.4914H22.3207V23.4599H26.6487V10.4914H32.2229V18.6189C32.2229 22.002 33.5613 23.4625 37.0636 23.4625H39.2959V19.7797H38.4361Z"
      fill="black"
    />

    <path
      d="M53.9573 13.6764C53.6496 11.52 51.8275 10.2272 49.7003 10.2272C47.5705 10.2272 45.8194 11.487 45.375 13.6764H53.9573ZM45.3382 16.2645C45.6485 18.718 47.468 20.1124 49.7687 20.1124C51.5882 20.1124 52.9976 19.2818 53.8205 17.956H58.251C57.2203 21.4712 53.9573 23.7266 49.6662 23.7266C44.481 23.7266 40.842 20.2114 40.842 15.2028C40.842 10.1942 44.6888 6.57747 49.7713 6.57747C54.8881 6.57747 58.5955 10.2273 58.5955 15.2028C58.5955 15.5686 58.5613 15.9318 58.4929 16.2645H45.3382Z"
      fill="black"
    />

    <path
      d="M86.1119 15.1698C86.1119 12.4826 84.0163 10.4254 81.4421 10.4254C78.868 10.4254 76.7723 12.4166 76.7723 15.1698C76.7723 17.89 78.868 19.9143 81.4421 19.9143C84.0163 19.9117 86.1119 17.857 86.1119 15.1698ZM72.5128 6.87718H76.7355V9.03352C78.0055 7.50706 79.8934 6.58002 82.1599 6.58002C86.7955 6.58002 90.4346 10.2628 90.4346 15.1368C90.4346 20.0133 86.7271 23.7291 82.0574 23.7291C79.8934 23.7291 78.1081 22.8986 76.8723 21.5068V30.2871H72.5128V6.87718Z"
      fill="black"
    />

    <path
      d="M105.656 15.1698C105.656 12.3836 103.563 10.4254 100.986 10.4254C98.412 10.4254 96.3164 12.4166 96.3164 15.1698C96.3164 17.89 98.412 19.9143 100.986 19.9143C103.563 19.9117 105.656 17.956 105.656 15.1698ZM105.693 23.4625V21.3061C104.423 22.7996 102.532 23.7266 100.268 23.7266C95.5644 23.7266 91.9938 20.0768 91.9938 15.1698C91.9938 10.2933 95.7012 6.58002 100.371 6.58002C102.569 6.58002 104.423 7.50961 105.693 8.96749V6.87718H109.916V23.4625H105.693Z"
      fill="black"
    />

    <path
      d="M64.9007 8.50269C64.9007 8.50269 65.9762 6.58002 68.6081 6.58002C69.7335 6.58002 70.4619 6.95337 70.4619 6.95337V11.1848C70.4619 11.1848 68.8737 10.2374 67.4144 10.4279C65.9551 10.6184 65.0322 11.9137 65.0375 13.6459V23.465H60.678V6.87972H64.9007V8.50269Z"
      fill="black"
    />

    <path
      d="M129.638 6.87717L118.976 30.2337H114.48L118.666 21.2045L111.588 6.87717H116.684L120.825 16.4499L125.074 6.87717H129.638Z"
      fill="black"
    />

    <path
      d="M163.673 6.50128L153.103 0.608809C150 -1.12083 146.122 1.04059 146.122 4.50241V5.1069C146.122 5.65297 146.424 6.1584 146.913 6.43016L148.909 7.54262C149.495 7.87027 150.226 7.46135 150.226 6.80861V5.30501C150.226 4.55321 151.068 4.08334 151.741 4.45924L160.896 9.56435C161.57 9.94025 161.57 10.88 160.896 11.2534L151.741 16.3585C151.068 16.7344 150.226 16.2645 150.226 15.5127V14.7126C150.226 11.2508 146.348 9.08686 143.243 10.819L132.673 16.7115C129.57 18.4412 129.57 22.7691 132.673 24.4987L143.243 30.3912C146.345 32.1208 150.226 29.9594 150.226 26.4976V25.8931C150.226 25.347 149.924 24.8441 149.435 24.5698L147.439 23.4548C146.853 23.1272 146.122 23.5361 146.122 24.1889V25.6925C146.122 26.4443 145.281 26.9141 144.607 26.5382L135.452 21.4331C134.779 21.0572 134.779 20.1175 135.452 19.7416L144.607 14.6364C145.281 14.2605 146.122 14.7304 146.122 15.4822V16.2823C146.122 19.7441 150 21.9081 153.103 20.1759L163.673 14.2834C166.776 12.5588 166.776 8.23092 163.673 6.50128Z"
      fill="black"
    />
  </Icon>
)
