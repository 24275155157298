import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Link, SPOONFLOWER_BASE_URL } from '@modules/app'
import { HiExternalLink } from 'react-icons/hi'
import { useIntl } from 'react-intl'

export type TAccountModalProps = {
  onClickContinueAsGuest: () => void
}

export const AccountModal: React.FC<TAccountModalProps> = (props) => {
  const { onClickContinueAsGuest } = props
  const intl = useIntl()

  return (
    <Modal isCentered={true} isOpen={true} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          <Heading
            as="h2"
            marginBottom={{ base: '0', md: '1' }}
            size="xl"
            variant="serif"
          >
            {intl.formatMessage({ id: 'checkout.accountModal.title' })}
          </Heading>
        </ModalHeader>

        <ModalBody textAlign="center">
          <Text marginBottom="6" size="lg">
            {intl.formatMessage({ id: 'checkout.accountModal.text' })}
          </Text>

          <Button
            colorScheme="teal"
            onClick={onClickContinueAsGuest}
            variant="solid"
            width="full"
          >
            {intl.formatMessage({
              id: 'checkout.accountModal.continueAsGuestButton',
            })}
          </Button>

          <Box marginBottom={{ base: '4', md: '6' }} marginTop="12">
            <Text
              as="span"
              backgroundColor="warm-white"
              padding="3"
              position="relative"
              textTransform="uppercase"
              zIndex="1"
            >
              {intl.formatMessage({ id: 'checkout.accountModal.divider' })}
            </Text>

            <Divider position="relative" top="-3" />
          </Box>

          <Button
            as={Link}
            href={`${SPOONFLOWER_BASE_URL}/login`}
            variant="outline"
            width="full"
          >
            <HStack spacing="2">
              <Text as="span" textStyle="title-case">
                {intl.formatMessage({
                  id: 'checkout.accountModal.loginButton',
                })}
              </Text>
              <HiExternalLink height="18px" width="18px" />
            </HStack>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
