import { useEffect, useMemo } from 'react'

import { debounce } from './debounce'

// utility hook to debounce a function
export const useDebounce = <F extends any[], R = void>(
  fn: (...args: any) => any,
  ms: number
): ((...args: any) => Promise<any>) => {
  const [debouncedFn, teardown] = useMemo(() => debounce(fn, ms), [fn, ms])

  useEffect(() => () => teardown(), [])

  return debouncedFn
}
