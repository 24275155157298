import { InfoBox } from '@modules/app/components/InfoBox'
import { getCurrencySymbol } from '@modules/app/utils/helpers'
import { CartFragment, getCartTotals, useCart } from '@modules/commercetools'
import { useIntl } from 'react-intl'
import { CartSummaryItem } from './CartSummaryItem'
import { isSubscriptionTypeProductTheOnlyProductTypeInCart } from '@modules/cart/utils'

export default function ShippingCartSummaryItem({
  currency,
}: {
  currency: string
}) {
  const { cart } = useCart()
  const { lineItems } = cart
  const { shipping, discountedShipping } = getCartTotals(cart as CartFragment)
  const intl = useIntl()
  const isTheOnlyProductTypeInCartSubscription =
    lineItems && isSubscriptionTypeProductTheOnlyProductTypeInCart(lineItems)
  const infoBox = !shipping && !isTheOnlyProductTypeInCartSubscription && (
    <InfoBox
      ariaLabel={intl.formatMessage({ id: 'cart.toolTip.shipping.ariaLabel' })}
      label={intl.formatMessage({ id: 'cart.toolTip.shipping.text' })}
    />
  )
  const value = isTheOnlyProductTypeInCartSubscription
    ? intl.formatNumber(0, {
        currency,
        style: 'currency',
      })
    : shipping
    ? intl.formatNumber(shipping / 100, {
        currency,
        style: 'currency',
      })
    : intl.formatMessage(
        { id: 'cart.shipping.shippingEstimateInfo' },
        { currencySymbol: getCurrencySymbol(currency) || '$' }
      )

  let discountedValue
  // discountedValue being undefined if neither of the following 2 conditions are met will render the shipping price without strike-through
  if (discountedShipping > 0) {
    discountedValue = intl.formatNumber(discountedShipping / 100, {
      currency,
      style: 'currency',
    })
  } else if (discountedShipping === 0) {
    discountedValue = intl
      .formatMessage({ id: 'cart.shipping.free' })
      .toUpperCase()
  }

  return (
    <CartSummaryItem
      infoBox={infoBox}
      label={`${intl.formatMessage({ id: 'cart.summary.shipping' })}:`}
      value={value}
      discountedValue={discountedValue}
      dataTestId="summary-price-shipping"
    />
  )
}
