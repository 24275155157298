import { ProductCardLayout } from '../types'

export const getGridConfig: (labels: {
  quantityLabel: string
  itemPriceLabel: string
}) => ProductCardLayout = ({ quantityLabel, itemPriceLabel }) => ({
  2: null,
  3: null,
  4: {
    xs: {
      columnGap: '3',
      rowGap: '2',
      templateAreas: `
        'nameAndArtist .'
        'img price'
        'img details'
        'quantity .'
        'buttons .'
      `,
      templateColumns: '128px auto',
      templateRows: 'auto auto 1fr auto auto',
    },
    md: {
      columnGap: '3',
      rowGap: '2',
      templateAreas: `
        'nameAndArtist .'
        'img price'
        'img details'
        'quantity buttons'
      `,
      templateColumns: '128px auto',
      templateRows: 'auto auto 1fr auto',
    },
    lg: {
      columnGap: '6',
      rowGap: '3',
      templateAreas: `
        'img nameAndArtist'
        'img price'
        'img details'
        'quantity buttons'
      `,
      templateColumns: '192px auto',
      templateRows: 'auto auto 1fr auto',
    },
  },
  5: null,
})
