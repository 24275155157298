import { Icon, IconProps } from '@chakra-ui/react'

export const Warning: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    height="16px"
    viewBox="0 0 24 16"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.8134 18.3333C1.63181 18.3333 1.47108 18.2901 1.33119 18.2037C1.19131 18.1173 1.08251 18.0035 1.00479 17.8621C0.92003 17.723 0.873411 17.5723 0.864934 17.41C0.856475 17.2477 0.901878 17.0841 1.00114 16.9191L10.1847 1.08072C10.284 0.915749 10.4036 0.795865 10.5434 0.721062C10.6833 0.646279 10.8355 0.608887 11 0.608887C11.1645 0.608887 11.3167 0.646279 11.4566 0.721062C11.5964 0.795865 11.716 0.915749 11.8153 1.08072L20.9989 16.9191C21.0981 17.0841 21.1435 17.2477 21.1351 17.41C21.1266 17.5723 21.08 17.723 20.9952 17.8621C20.9175 18.0035 20.8087 18.1173 20.6688 18.2037C20.5289 18.2901 20.3682 18.3333 20.1866 18.3333H1.8134ZM2.19167 17.1666H19.8083L11 1.99993L2.19167 17.1666ZM11 15.5512C11.2034 15.5512 11.3739 15.4824 11.5116 15.3448C11.6491 15.2072 11.7179 15.0367 11.7179 14.8333C11.7179 14.6298 11.6491 14.4593 11.5116 14.3217C11.3739 14.1841 11.2034 14.1153 11 14.1153C10.7966 14.1153 10.6261 14.1841 10.4884 14.3217C10.3509 14.4593 10.2821 14.6298 10.2821 14.8333C10.2821 15.0367 10.3509 15.2072 10.4884 15.3448C10.6261 15.4824 10.7966 15.5512 11 15.5512ZM11.0008 12.9487C11.1663 12.9487 11.3048 12.8927 11.4162 12.7809C11.5276 12.6691 11.5833 12.5306 11.5833 12.3653V7.69866C11.5833 7.53338 11.5274 7.39484 11.4154 7.28304C11.3035 7.17123 11.1647 7.11533 10.9992 7.11533C10.8337 7.11533 10.6952 7.17123 10.5838 7.28304C10.4724 7.39484 10.4167 7.53338 10.4167 7.69866V12.3653C10.4167 12.5306 10.4726 12.6691 10.5846 12.7809C10.6966 12.8927 10.8353 12.9487 11.0008 12.9487Z"
      fill="#0C0D0E"
    />
  </Icon>
)
