export const AfterpayPlacement = ({
  minAmount,
  maxAmount,
  amount,
  currency,
}: {
  minAmount: number
  maxAmount: number
  amount: string
  currency: string
}) => {
  
  return (
    /* @ts-ignore */
    <afterpay-placement
      data-min={minAmount}
      data-max={maxAmount}
      data-badge-theme="black-on-white"
      data-modal-theme="white"
      data-locale="en_US"
      data-currency={currency}
      data-amount={amount}
      data-intro-text="Pay in"
      /* @ts-ignore */
    ></afterpay-placement>
  )
}
