import { Flex, Text } from '@chakra-ui/react'
import { Warning } from '@modules/ui/icons/Warning'
import { useIntl } from 'react-intl'

export const NoChargeMessage: React.FC = ({}) => {
  const intl = useIntl()

  return (
    <Flex marginBottom={3} marginTop={3}>
      <Warning width="28px" height="28px" />
      <Text marginLeft={2}>
        {intl.formatMessage({
          id: 'cart.summary.noChargeMessage',
        })}
      </Text>
    </Flex>
  )
}
