import { FormEvent, useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Box,
  Image,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { Link, SPOONFLOWER_BASE_URL } from '@modules/app'
import {
  gaTrackApplyCoupon,
  gaTrackRemoveCoupon,
  useCart,
} from '@modules/commercetools'
import { AccountModal } from '../../../checkout/components/AccountModal'
import axios from 'axios'
import useWhoAmI from '@modules/app/hooks/useWhoAmI'

interface CartPromotionProps {
  setIsUpdatingCoupon: React.Dispatch<React.SetStateAction<boolean>>
}

const ANIMATION_DURATION = 600

export const CartPromotion = ({ setIsUpdatingCoupon }: CartPromotionProps) => {
  const intl = useIntl()
  const [code, setCode] = useState('')
  const [discountCodeMessage, setDiscountCodeMessage] = useState('')
  const [error, setError] = useState('')
  const [showModal, setShowModal] = useState(false)

  const { applyCode, removeCode, cart, fetchCart } = useCart()
  const [currentCode] = cart.discountCodes ?? [undefined]
  const { data: user } = useWhoAmI()
  const { guest } = user ?? {}

  const handleRunAnimation = () =>
    setTimeout(() => setIsUpdatingCoupon(false), ANIMATION_DURATION) // Animation for Total Price

  const handleSubmitCouponCode = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    currentCode ? handleRemoveCouponCode() : handleAddCouponCode()
  }

  const resetError = () => (error.length ? setError('') : null)

  const shouldShowAccountModal = guest && showModal

  const loginButton = (
    <Link
      color="gray.600"
      borderBottomWidth="1px"
      borderBottomColor="gray.600"
      onClick={() => setShowModal(true)}
      href="javascript:void(0);"
      fontWeight="regular"
    >
      {intl.formatMessage({ id: 'cart.couponCode.doesnotMatchCartLogIn' })}
    </Link>
  )

  const termsAndConditionsLink = (
    <Link
      href={`${SPOONFLOWER_BASE_URL}/en/promo-codes-coupons-discounts`}
      target="_blank"
      color="gray.600"
      fontWeight="regular"
    >
      {intl.formatMessage({ id: 'cart.couponCode.doesnotMatchCartTermsLink' })}
    </Link>
  )

  const complicatedErrorMessage = (
    <>
      <Text as="p" color="red.500" size="sm">
        {intl.formatMessage({ id: 'cart.couponCode.doesnotMatchCart1' })}
      </Text>
      <Text as="span" size="sm" variant="p">
        {intl.formatMessage(
          {
            id: guest
              ? 'cart.couponCode.doesnotMatchCart2Guest'
              : 'cart.couponCode.doesnotMatchCart2LoggedIn',
          },
          {
            loginButton,
            termsAndConditionsLink,
          }
        )}
      </Text>
    </>
  )

  const DOES_NOT_MATCH_ERROR = 'DoesNotMatchCart'

  const validateCoupon = () => {
    setError('')
    if (currentCode) {
      const { discountCode } = currentCode ?? {}
      const { cartDiscounts, isActive } = discountCode ?? {}
      const discountCodeTargetTypes = cartDiscounts?.reduce(
        (acc, cartDiscount) => {
          if (cartDiscount?.target?.type) acc.push(cartDiscount?.target?.type)
          return acc
        },
        [] as Array<string>
      )
      if (
        isActive &&
        discountCodeTargetTypes?.includes('shipping') &&
        !cart?.shippingInfo
      ) {
        setDiscountCodeMessage(
          intl.formatMessage({
            id: 'cart.coupon.shipping.message',
          })
        )
      }
      if (currentCode.state === 'MaxApplicationReached') {
        setError(intl.formatMessage({ id: 'cart.couponCode.usageLimitError' }))
      }
      if (currentCode.state === 'DoesNotMatchCart') {
        setError(DOES_NOT_MATCH_ERROR)
      }
    } else {
      setDiscountCodeMessage('')
    }
  }

  useEffect(() => {
    validateCoupon()
  }, [currentCode, cart])

  const handleAddCouponCode = () => {
    if (!code.length) return

    setIsUpdatingCoupon(true)

    applyCode.mutate(
      { code },
      {
        onSuccess: () => {
          setCode('')
          resetError()
          handleRunAnimation()
          gaTrackApplyCoupon({
            cart,
            couponCode: code,
          })
        },
        onError: async () => {
          const { data: legacyPromoCodeData } = await axios.post(
            '/api/promotions/check-promo-code',
            {
              code,
            }
          )

          if (legacyPromoCodeData.success) {
            resetError()
            fetchCart()
          } else {
            setError(intl.formatMessage({ id: 'cart.couponCode.error' }))
          }
          setIsUpdatingCoupon(false)
        },
      }
    )
  }

  const handleRemoveCouponCode = () => {
    if (!currentCode) return

    setIsUpdatingCoupon(true)

    removeCode.mutate(
      {
        id: currentCode.discountCodeRef.id,
        typeId: currentCode.discountCodeRef.typeId,
      },
      {
        onSuccess: () => {
          handleRunAnimation()

          gaTrackRemoveCoupon({
            cart,
          })
        },
      }
    )
  }

  return (
    <>
      {shouldShowAccountModal && (
        <AccountModal onClickContinueAsGuest={() => setShowModal(false)} />
      )}
      <form onSubmit={handleSubmitCouponCode}>
        <InputGroup size="md" marginTop={2} height={10}>
          <FormControl isInvalid={error.length > 0} marginRight={2}>
            <Input
              aria-label={intl.formatMessage({
                id: 'cart.summary.label.couponCode',
              })}
              variant="outline"
              readOnly={Boolean(currentCode)}
              value={currentCode ? currentCode.discountCode?.code : code}
              onChange={(e) => setCode(e.target.value)}
            />
            {code.length > 0 && (
              <InputRightElement>
                <Button
                  onClick={() => setCode('')}
                  aria-label={intl.formatMessage({
                    id: 'action.clearInput.ariaLabel',
                  })}
                  padding={0}
                  backgroundColor="transparent"
                  _hover={{ backgroundColor: 'transparent' }}
                  _focus={{ backgroundColor: 'transparent' }}
                >
                  <Image
                    src="/SVG/close-icon.svg"
                    objectFit="cover"
                    role="presentation"
                    alt="close-icon"
                  />
                </Button>
              </InputRightElement>
            )}
          </FormControl>

          <Button
            type="submit"
            variant="outline"
            colorScheme="white"
            isLoading={currentCode ? removeCode.isLoading : applyCode.isLoading}
          >
            <span>
              {intl.formatMessage({
                id: currentCode ? 'action.resetCoupon' : 'action.applyCoupon',
              })}
            </span>
          </Button>
        </InputGroup>

        <Box marginTop={2}>
          {error === DOES_NOT_MATCH_ERROR ? (
            complicatedErrorMessage
          ) : (
            <Text as="p" color="red.500" size="sm">
              {error}
            </Text>
          )}
        </Box>
        <Box marginTop={2} maxWidth="72">
          <Text as="p" color="gray.600" size="sm">
            {discountCodeMessage}
          </Text>
        </Box>
      </form>
    </>
  )
}
