import { Flex } from '@chakra-ui/react'
import axios from 'axios'
import {
  NEXT_PUBLIC_AFTERPAY_CANCEL,
  NEXT_PUBLIC_AFTERPAY_ORIGIN,
  NEXT_PUBLIC_AFTERPAY_REDIRECT,
} from '@modules/app'
import useUserPreferences from '@modules/app/hooks/useUserPreferences'
import { useCart } from '@modules/commercetools'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AfterpayCheckoutProps, AfterpayConfigResponse } from 'types'
import { AFTERPAY_ERRORS } from 'utils/afterpay'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { PATHS } from 'constants/paths'
import {
  cartPageShowAfterpay,
  isSubscriptionTypeProductInCart,
  showAfterpay,
} from '@modules/cart/utils'
import { useComposable } from '@modules/oriuminc/base'
import useWhoAmI from '@modules/app/hooks/useWhoAmI'
import { AfterpayPlacement } from './AfterpayPlacement'

export const AfterPay = ({
  showUnavailable,
  setShowButton,
  isCartSummary,
  amount,
  selectedCountry,
}: {
  isCartSummary?: boolean
  showUnavailable?: boolean
  setShowButton?: Dispatch<SetStateAction<boolean>>
  amount?: { amount: string; currency: string }
  selectedCountry?: string
}) => {
  const [afterpayConfig, setAfterpayConfig] = useState<AfterpayConfigResponse>()
  const [userCountry, setUserCountry] = useState<string>('US')

  const { currency } = useComposable()
  const { data: user } = useWhoAmI()
  const {
    preferences: { country: preferredCountry },
  } = useUserPreferences()

  const intl = useIntl()
  const { cart } = useCart()
  const router = useRouter()

  const isUSSelected = selectedCountry === 'US'
  const { totalPrice, shippingAddress, shippingInfo, lineItems } = cart
  const { centAmount } = totalPrice || {}
  const { firstName, lastName, streetName, streetNumber, postalCode, country } =
    shippingAddress || {}
  const { INVALID_AMOUNT, INVALID_COUNTRY, INVALID_CURRENCY } = AFTERPAY_ERRORS
  let { config, errorCode } = afterpayConfig || {}
  const priceRange = config
    ? `${config.minimumAmount.amount} - ${config.maximumAmount.amount}`
    : ''

  const isSubscriptionInOrder =
    lineItems && isSubscriptionTypeProductInCart(lineItems)

  useEffect(() => {
    // Checks if order is eligible for Afterpay
    const fetchAfterpayConfig = async (orderData: AfterpayCheckoutProps) => {
      try {
        const config = await axios.post(`/api/afterpay/checkout`, {
          orderData: orderData,
          isSubscriptionInOrder,
        })
        const configJSON = JSON.parse(config.data.data)

        return setAfterpayConfig(configJSON)
      } catch (e) {
        console.log('Error fetching Afterpay config: ', e)
      }
    }
    const amount = {
      amount:
        (cart.taxedPrice?.totalGross?.centAmount ||
          cart.totalPrice?.centAmount ||
          0) / 100,
      currency:
        cart.taxedPrice?.totalGross?.currencyCode ||
        cart.totalPrice?.currencyCode ||
        '',
    }
    const orderData = {
      amount,
      consumer: {
        givenNames: user?.screen_name || '',
        surname: user?.screen_name || '',
        email: user?.email || '',
      },
      billing: {
        name: `${firstName} ${lastName}`,
        line1: `${streetNumber} ${streetName}`,
        postcode: postalCode || '',
        country: cart?.billingAddress?.country || '',
      },
      shipping: {
        name: `${firstName} ${lastName}`,
        line1: `${streetNumber} ${streetName}`,
        postcode: postalCode || '',
        country: country || '',
      },
      userDetails: {
        country: selectedCountry || country || '',
        currency: currency || '',
      },
      merchant: {
        redirectConfirmUrl: NEXT_PUBLIC_AFTERPAY_REDIRECT,
        redirectCancelUrl: NEXT_PUBLIC_AFTERPAY_CANCEL,
        popupOriginUrl: NEXT_PUBLIC_AFTERPAY_ORIGIN,
      },
      taxAmount: {
        amount: (cart.taxedPrice?.totalTax?.centAmount || 0) / 100,
        currency: cart.taxedPrice?.totalTax?.currencyCode || '',
      },
      shippingAmount: {
        amount: (shippingInfo?.price?.centAmount || 0) / 100,
        currency: shippingInfo?.price?.currencyCode || '',
      },
    }

    if (
      userCountry === preferredCountry &&
      totalPrice?.currencyCode === currency
    ) {
      fetchAfterpayConfig(orderData)
    } else if (preferredCountry !== userCountry) {
      setUserCountry(preferredCountry || '')
    }
  }, [
    userCountry,
    preferredCountry,
    selectedCountry,
    currency,
    router.asPath,
    cart.totalPrice?.currencyCode,
    cart?.taxedPrice?.totalGross?.centAmount,
  ])

  const isCartPage = router?.asPath === PATHS.CART
  const showAfterpayInCart =
    isCartSummary && cartPageShowAfterpay(isCartPage, afterpayConfig)
  const showAfterpayMessage = showAfterpay(
    isCartPage,
    afterpayConfig,
    !!showUnavailable,
    currency,
    !!showAfterpayInCart
  )
  const minAfterpayLimit = parseInt(config?.minimumAmount?.amount || '0') * 100
  const maxAfterpayLimit = parseInt(config?.maximumAmount?.amount || '0') * 100
  const afterpayOutsideOfRange =
    cart.taxedPrice?.totalGross?.centAmount < minAfterpayLimit ||
    cart.taxedPrice?.totalGross?.centAmount > maxAfterpayLimit

  if (
    setShowButton &&
    (!showAfterpayMessage ||
      afterpayOutsideOfRange ||
      !isUSSelected ||
      isSubscriptionInOrder)
  ) {
    setShowButton(false)
  } else if (
    setShowButton &&
    showAfterpayMessage &&
    !afterpayOutsideOfRange &&
    isUSSelected
  ) {
    setShowButton(true)
  }

  const afterpayDataCurrency =
    amount?.currency || cart?.totalPrice?.currencyCode
  const afterpayDataAmount =
    amount?.amount || ((centAmount || 0) / 100).toString()
  const afterpayMinAmount = parseInt(config?.minimumAmount.amount || '') || 0
  const afterpayMaxAmount = parseInt(config?.maximumAmount.amount || '') || 0

  return config ? (
    <Flex
      alignItems="left"
      justifyContent="left"
      textAlign="center"
      flexWrap="wrap"
      fontSize="sm"
    >
      <Script
        async
        defer
        type="text/javascript"
        src="https://js.afterpay.com/afterpay-1.x.js"
        data-analytics-enabled
      />
      {showAfterpayMessage && isUSSelected && !isSubscriptionInOrder && (
        <AfterpayPlacement
          minAmount={afterpayMinAmount}
          maxAmount={afterpayMaxAmount}
          amount={afterpayDataAmount}
          currency={afterpayDataCurrency}
        />
      )}
    </Flex>
  ) : (
    <></>
  )
}
