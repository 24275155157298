import { CartLineItemFragment } from '@modules/commercetools'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ProductStatuses } from 'pages/api/commercetools/product-statuses'

interface ProductStatusesResponse {
  productStatuses: Partial<Record<string, ProductStatuses>>
}

function checkIfAllProductsAreSalable(
  skus: Array<string>,
  productStatuses: Partial<Record<string, ProductStatuses>>
) {
  for (const sku of skus) {
    const statuses = productStatuses[sku]

    if (
      statuses &&
      (statuses['product-status'] !== 'Active' ||
        statuses['variant-status'] !== 'Active')
    ) {
      return false
    }
  }

  return true
}

function useProductStatuses(
  lineItems: Array<CartLineItemFragment> = [],
  cartID: string = ''
) {
  const productStatusesKey = ['productStatuses', cartID]
  const skus = lineItems.map(({ variant }) => variant?.sku!)
  const query = useQuery(
    productStatusesKey,
    async (context) => {
      const productStatuses = await axios
        .post<ProductStatusesResponse>(
          '/api/commercetools/product-statuses',
          {
            skus,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => response.data.productStatuses)

      /**
       * This first "areAllProductsSalable" is needed on "refetch()",
       * so that we may determine if all products are salable without a re-render.
       */
      const areAllProductsSalable = checkIfAllProductsAreSalable(
        skus,
        productStatuses
      )

      return {
        areAllProductsSalable,
        productStatuses,
      }
    },
    {
      enabled: false,
      initialData: {
        areAllProductsSalable: true,
        productStatuses: {} as Partial<Record<string, ProductStatuses>>,
      },
      retry: false,
    }
  )
  const {
    data: { productStatuses },
  } = query
  /**
   * This second "areAllProductsSalable" is needed on re-render,
   * so we know if the user has removed non-salable products from their cart.
   */
  const areAllProductsSalable = checkIfAllProductsAreSalable(
    skus,
    productStatuses
  )

  return {
    ...query,
    data: {
      areAllProductsSalable,
      productStatuses,
    },
  }
}

export { useProductStatuses as default }
