import { InfoBox } from '@modules/app/components/InfoBox'
import { CartFragment, getCartTotals, useCart } from '@modules/commercetools'
import { useComposable } from '@modules/oriuminc/base'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'

import useWhoAmI from '@modules/app/hooks/useWhoAmI'
import { defineTaxIdentifier, handleCalculateTaxValue } from '../helpers'
import { CartSummaryItem } from './CartSummaryItem'
import { countriesWithDuty } from '../constant'

interface TaxCartSummaryItemProps {
  checkoutStep?: string
  addon?: ReactNode
}

const TaxCartSummaryItem: React.FC<TaxCartSummaryItemProps> = ({
  checkoutStep,
  addon,
}) => {
  const { cart } = useCart()
  const { country } = cart.shippingAddress ?? {}
  const { data: user } = useWhoAmI()
  const { tax } = getCartTotals(cart as CartFragment)
  const { currency } = useComposable()
  const intl = useIntl()
  const displayAddon = user && country !== 'US' && checkoutStep === 'step1'
  const countryCode = countriesWithDuty.includes(country)
    ? 'dutyCountry'
    : country
  const taxIdentifierName = defineTaxIdentifier(countryCode)

  // The InfoBox is not present after the Shipping step.
  const infoBox = checkoutStep === 'step1' && (
    <InfoBox
      ariaLabel={intl.formatMessage({ id: 'cart.toolTip.tax.ariaLabel' })}
      label={intl.formatMessage({ id: 'cart.toolTip.tax.text' })}
      placement="bottom-start"
    />
  )

  const value = handleCalculateTaxValue(
    tax,
    intl.formatNumber,
    intl.formatMessage,
    {
      currency,
      style: 'currency',
    }
  )

  return (
    <CartSummaryItem
      infoBox={!user && infoBox}
      label={`${intl.formatMessage({ id: taxIdentifierName })}:`}
      value={value}
      addon={displayAddon ? addon : null}
      dataTestId="summary-price-tax"
    />
  )
}

export default TaxCartSummaryItem
