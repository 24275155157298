import { Flex, Image, Link } from '@chakra-ui/react'
import {
  NEXT_PUBLIC_ID_ME_CALLBACK_URL,
  NEXT_PUBLIC_ID_ME_CLIENT_ID,
  LEARN_MORE_ID_ME_LINK,
} from '@modules/app'
import { useCart } from '@modules/commercetools'
import { useComposable } from '@modules/oriuminc/base'
import { ID_ME_CALLBACK_URL } from 'constants/paths'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export const IdMe = () => {
  const intl = useIntl()
  const router = useRouter()
  const { path } = useComposable()
  const urlSearchParams = new URLSearchParams(router.asPath)
  const idMeAccessToken = urlSearchParams.get(ID_ME_CALLBACK_URL)
  const { cartApplyIdMeDiscount } = useCart()

  useEffect(() => {
    if (idMeAccessToken) {
      cartApplyIdMeDiscount.mutate({ code: idMeAccessToken })
      // router.push(path.getCart())
    }
  }, [])

  return (
    <>
      <Flex
        paddingTop={6}
        paddingInline={{ base: 10, md: 16 }}
        paddingBottom={{ base: 6, xl: 12 }}
        alignItems="center"
        justifyContent="center"
        flexDirection={{ base: 'column', sm: 'row' }}
        gap={{ base: 3, md: 0 }}
        width="100%"
        maxWidth="488px"
      >
        <Link
          backgroundColor="warm-white"
          width="220px"
          height="43px"
          padding={0}
          _hover={{ backgroundColor: 'warm-white' }}
          borderColor="transparent"
          marginRight={5}
          href={`https://groups.id.me/?&scopes=teacher,student,military,responder,nurse&client_id=${NEXT_PUBLIC_ID_ME_CLIENT_ID}&redirect_uri=${NEXT_PUBLIC_ID_ME_CALLBACK_URL}&response_type=token`}
        >
          {
            // TODO[i18n]: the alt text
          }
          <Image
            src="/img/id.me-button.svg"
            width="100%"
            height="100%"
            alt="Verify with ID.me"
          />
        </Link>

        <Link
          color="primary"
          fontSize="md"
          _active={{ color: 'primary' }}
          isExternal
          href={LEARN_MORE_ID_ME_LINK}
        >
          {intl.formatMessage({ id: 'cart.summary.learnMore' })}
        </Link>
      </Flex>
    </>
  )
}
